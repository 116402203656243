import React from "react"
import { Seo } from "src/component/common/seo"
import Casestudies from "src/component/layout/casestudies"
import Button from "src/component/common/button"
import { StaticImage } from "gatsby-plugin-image"
import PredictiveAiCasestudyList from "src/component/list/predictive-aiCasestudyList"

export default function PredictiveAi() {
    return (
        <>
         <Casestudies>
          <div className="predictive-ai inner-content">
            <section className="introduction__mv">
                    <div className="introduction__mv__inner c-inner-primary">
                    <div className="introduction__mv__img">
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_predictive-ai.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="pc-show"
                            />
                            <StaticImage
                              src="../../assets/img/pages/casestudies/casestudies_predictive-ai-sp.png"
                              alt="株式会社DATAFLUCT（データフラクト）"
                            placeholder="blurred"
                            className="sp-show"
                            />
                    </div>

                        <div className="introduction__mv__content">
                                <h2 className="introduction__mv__content__title">
                                 <p>予測AI</p>
                                  <span>PREDICTIVE AI</span>
                                </h2>
                                <p className="introduction__mv__lead">
                                  多様な外部データと最先端のAI技術を統合し、高精度かつリアルタイムな未来予測を提供。
                                  <br />
                                    在庫、生産、物流、設備異常、市場トレンドの予測精度を飛躍的に向上させ、
                                    <br className="c-pc" />
                                    経営の戦略的判断とリスク低減を実現。
                               </p>
                         </div>
        
                    </div>
                </section>
                



                <section className="introduction__content">
                    <div className="introduction__content__inner c-inner-primary">
                        <div className="introduction__content__title">
                            <h2 className="js-scroll">
                                <div><div><span>高精度AI予測と<br />実行可能な最適化ソリューションで、収益と効率を劇的に向上</span></div></div>
                            </h2>
                        </div>
                        <div className="introduction__content__textwrap">
                            <ul>
                                <li className="introduction__content__text">
                                 <h3>AI需要予測で在庫最適化</h3>
                                 <p>
                                    天候やイベントなど外部データを統合し、高精度の需要予測で在庫リスクを低減。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>IoT活用で設備予知保全</h3>
                                 <p>
                                    IoTセンサーが設備異常をリアルタイムで予測。停止リスクを防ぎ稼働率向上。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AI活用で商品戦略高度化</h3>
                                 <p>
                                    市場トレンドをAIが予測し、最適な価格・投入時期を判断し収益性を最大化。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AIで顧客プロファイル化</h3>
                                 <p>
                                    決済データをAIが解析し、顧客特性に基づく精緻なCRMでLTV向上を支援。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AI商圏分析で出店最適化</h3>
                                 <p>
                                    人口や競合状況をAIが分析し、高精度な出店予測で収益性の高い店舗展開を支援。
                                 </p>
                                </li>
                                <li className="introduction__content__text">
                                 <h3>AI市場予測で意思決定支援</h3>
                                 <p>
                                    SNSや経済指標など外部データから市場トレンドを予測し、迅速な戦略判断を支援。
                                 </p>
                                </li>

                            </ul>
         
                        </div>
                    </div>
                </section>

                <section className="p-top-casestudy">
                    <div className="p-top-casestudy__inner c-inner-primary">
                        <div className="p-top-casestudy__title c-title01">
                            <h2 className="p-top-casestudy__title__text c-title01__text">導入事例</h2>
                        </div>
                        <PredictiveAiCasestudyList />
                    </div>

                </section>
             </div>
           </Casestudies>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="PREDICTIVE AI"
            description=""
            pathname=""
        />
    )
}